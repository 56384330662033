import React, { useContext} from 'react';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';

const ExistingVisaResult = () => {
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  // eslint-disable-next-line no-unused-vars
  const [countryState, countryDispatch] = useContext(CountryContext);

  const continueToAssessmentHandler = () => {
    assessmentDispatch({
      type: 'SET_EXISTING_VISA_DONE',
    });
  };

  const resetAssessment = () => {
    countryDispatch({ type: 'RESET_COUNTRIES' });
    assessmentDispatch({ type: 'RESET_ASSESSMENT' });
  };

  const functionPrint = () => {
    // get id from app.js
    document.getElementById('__nonPrintable__').classList.add('__noPrint__');

    window.print();
    // remove __noPrint__ class from app

    document.getElementById('__nonPrintable__').classList.remove('__noPrint__');
  };

  return (
    <div className='row'>
       <div className='col-12 d-flex justify-content-end align-items-center mb-3'>
        {assessmentState.existingVisaResult.indicativeTime !== undefined && (
            <b>Indicative Immigration Lead Time: {
              assessmentState.existingVisaResult.indicativeTime === 1 ? '1 week' :
              `${assessmentState.existingVisaResult.indicativeTime} weeks`}
            </b>
        )}
      </div>
      <div className='col-12 d-flex justify-content-between align-items-center mb-3'>
        <span className='gw__existing-visa-header'>
          {assessmentState.existingVisaResult.title}
        </span>
        {assessmentState.existingVisaResult.icon}
      </div>

      <div className='col-12'>
        <p className='gw__existing-visa-subtext'>
            {assessmentState.existingVisaResult.subtext}
        </p>
      </div>

      <div className='col-12 d-flex justify-content-end mt-5'>
        {assessmentState.existingVisaResult.isContinuingToAssessment ? (
          <button
            className='btn btn-primary'
            onClick={continueToAssessmentHandler}
          >
            NEXT
          </button>
        ) : (
          <>
            {//check if existing visa has eligible output and adds pdf download button
            (assessmentState.existingVisaResult.title === 'Candidate is Eligible' || !assessmentState.existingVisaResult.title.toLowerCase().endsWith('required') 
            ) && (
              <button className='btn btn-primary' onClick={functionPrint}>
                SAVE AS PDF
              </button>
            )}

            <hr />
            <button className='btn btn-primary' onClick={resetAssessment}>
              EXIT ASSESSMENT
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ExistingVisaResult;
